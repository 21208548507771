import dictionary from '@/config/dictionary'

export default function () {
  return {
    analysisId: '0000-0000',
    bankAccountId: '0000-0000',
    createdDate: '2020-03-24T15:39:46.95Z',
    currentBankAccountFees: 60,
    comparisonOfBankData: [
      {
        bankDataId: 'AIB-BCA',
        bankName: 'AIB (GB)',
        bankAccountName: 'Business Current Account',
        independentServiceQuality: '45',
        accountHighlights: ['iBusiness Banking', 'Cash in Transit service', 'Virtual Account Manager (VAM)', 'Lodge cash at other Banks']
      },
      {
        bankDataId: 'BOI-BCA',
        bankName: 'Bank of Ireland (UK)',
        bankAccountName: 'Business Current Account',
        accountHighlights: ['Business on Line', '365 Digital and Phone Banking', '15 Minute loan applications', '365 Digital and Phone Banking']
      },
      {
        bankDataId: 'BARCLAYS-EPAY',
        bankName: 'Barclays',
        bankAccountName: `e-Payments (Up to £400k ${dictionary.turnover})`,
        independentServiceQuality: '59',
        annualSavings: -18,
        accountHighlights: ['Due to Covid-19 account applications are currently taking 6 weeks+ to review']
      },
      {
        bankDataId: 'BARCLAYS-EPAYPL',
        bankName: 'Barclays',
        bankAccountName: `e-Payments (£400k-£6.5m ${dictionary.turnover})`,
        independentServiceQuality: '59',
        annualSavings: -18,
        accountHighlights: ['Due to Covid-19 account applications are currently taking 6 weeks+ to review']
      },
      {
        bankDataId: 'COOP-BDPA',
        bankName: 'The Co-operative Bank',
        bankAccountName: 'Business Directplus (balance above £1k at all times)',
        independentServiceQuality: '48',
        annualSavings: 60,
        accountHighlights: ['Best Service from a Business Bank', 'Best Charity Banking Provider', 'Merchant Services', 'Manage your finances 24/7']
      },
      {
        bankDataId: 'COOP-BDPB',
        bankName: 'The Co-operative Bank',
        bankAccountName: 'Business Directplus (balance below £1k at any time)',
        independentServiceQuality: '48',
        annualSavings: -4.2,
        accountHighlights: ['Best Service from a Business Bank', 'Best Charity Banking Provider', 'Merchant Services', 'Manage your finances 24/7']
      },
      {
        bankDataId: 'DANSKE-SB',
        bankName: 'Danske Bank',
        bankAccountName: 'Danske Small Business',
        independentServiceQuality: '61',
        annualSavings: -90.7,
        accountHighlights: [
          'Manage FX and Interest Rate risk',
          'Free Webroot SecureAnywhere® antivirus software',
          'Integrated security in Business Banking',
          'Preferential rate at the Post Office®'
        ]
      },
      {
        bankDataId: 'HSBC-BCA',
        bankName: 'HSBC',
        bankAccountName: 'Business Current Account (Free Banking Period is 18 Months for Start-Up or 12 Months for Switcher)',
        independentServiceQuality: '51',
        annualSavings: 60,
        accountHighlights: ['Due to unprecedented demand HSBC are not currently accepting applications for new business bank accounts.']
      },
      {
        bankDataId: 'LLOYDS-BCA',
        bankName: 'Lloyds',
        bankAccountName: 'Lloyds Bank Switcher',
        independentServiceQuality: '56',
        annualSavings: -18,
        accountHighlights: ['Lloyds Bank have temporarily stopped accepting business account applications from customers looking to switch their banking']
      },
      {
        bankDataId: 'METRO-BA',
        bankName: 'Metro Bank',
        bankAccountName: 'Business (Daily Balance >£5k)',
        independentServiceQuality: '68',
        annualSavings: -4.2,
        accountHighlights: ['#1 for Online and Mobile banking', 'MCash Handling Service', 'Acceptcards® Merchant services', 'Integrate with Xero']
      },
      {
        bankDataId: 'METRO-BB',
        bankName: 'Metro Bank',
        bankAccountName: 'Business (Daily Balance <£5k)',
        independentServiceQuality: '68',
        annualSavings: 57.6,
        accountHighlights: ['#1 for Online and Mobile banking', 'MCash Handling Service', 'Acceptcards® Merchant services', 'Integrate with Xero']
      },
      {
        bankDataId: 'NATWEST-BA',
        bankName: 'NatWest',
        bankAccountName: 'Business Account',
        independentServiceQuality: '51',
        annualSavings: 0,
        accountHighlights: ['Due to Covid-19 account applications are currently taking longer than usual to process.']
      },
      {
        bankDataId: 'RBS-BCA',
        bankName: 'Rbs',
        bankAccountName: 'Business Current Account Type 40 (RBS)',
        independentServiceQuality: '41',
        accountHighlights: ['Due to Covid-19 account applications are currently taking longer than usual to process.']
      },
      {
        bankDataId: 'REVOLUT-FRE',
        bankName: 'Revolut',
        bankAccountName: 'Free',
        independentServiceQuality: '0',
        annualSavings: 60,
        accountHighlights: ['Integrate with business software', 'Digitised corporate cards', 'FX at Interbank Rate', 'Payments schedule and status overview']
      },
      {
        bankDataId: 'REVOLUT-GRW',
        bankName: 'Revolut',
        bankAccountName: 'Grow',
        independentServiceQuality: '0',
        annualSavings: -240,
        accountHighlights: ['Integrate with business software', 'Digitised corporate cards', 'FX at Interbank Rate', 'Payments schedule and status overview']
      },
      {
        bankDataId: 'SANTANDER-BCA',
        bankName: 'Santander',
        bankAccountName: 'Business Current Account',
        independentServiceQuality: '64',
        annualSavings: -30,
        accountHighlights: ['Santander has temporarily stopped accepting applications for new Business Current Accounts.']
      },
      {
        bankDataId: 'STARLING-BA',
        bankName: 'STARLING BANK',
        bankAccountName: 'Business Current Account',
        independentServiceQuality: '0',
        annualSavings: 60,
        accountHighlights: ['#1 Current Account 2019', 'Real-time banking', 'Spending Insights', 'Card Lock']
      },
      {
        bankDataId: 'TSB-BP',
        bankName: 'TSB',
        bankAccountName: 'Business Plus Account',
        independentServiceQuality: '44',
        annualSavings: 0,
        accountHighlights: ['Free day-to-day banking', 'Enterprise Nation Support', 'Free Square payments reader', 'Access to instant access savings account']
      },
      {
        bankDataId: 'UB-SA',
        bankName: 'Ulster Bank (NI)',
        bankAccountName: `Startup Current Account (Free banking for 18 months if trading <12 months and ${dictionary.turnover} <£1m)`,
        independentServiceQuality: '52',
        annualSavings: 0,
        accountHighlights: ['Business Growth Enablers', 'Help boosting connections and partnerships', 'Business Development Managers', 'Internet Banking']
      },
      {
        bankDataId: 'UB-BBA',
        bankName: 'Ulster Bank (NI)',
        bankAccountName: 'Business Bank Account',
        independentServiceQuality: '52',
        annualSavings: -37.94,
        accountHighlights: ['Business Growth Enablers', 'Help boosting connections and partnerships', 'Business Development Managers', 'Internet Banking']
      }
    ]
  }
}
