<template src="./static-banking.html"></template>
<style lang="scss" src="./static-banking.scss" scoped></style>

<script>
import { mapGetters } from 'vuex'
import { bankGetters } from '@/store/modules/bank/routines'
import BankLogo from '@/components/BankLogo.vue'
import AlertIntegrations from '@/components/AlertIntegrations'
import StatusProgressBar from '@/components/StatusProgressBar'
import staticBankingData from './static-banking-data'
import { forwardAIGetters } from '@/store/modules/forwardAI/routines'

export default {
  name: 'SavingsBanking',
  components: {
    AlertIntegrations,
    StatusProgressBar,
    BankLogo
  },
  data() {
    return {
      headers: [
        {
          text: 'Annual Savings',
          className: 'small-column',
          tooltip: 'This is an indication of potential annual savings based on your past 12 month transactions. Please note that terms and conditions may apply for each offer.'
        },
        {
          text: 'Overall service quality',
          className: 'small-column',
          tooltip:
            'As part of a regulatory requirement by the Competitions and Markets Authority (CMA), an independent survey was conducted to ask customers of the 14 largest business current account providers if they would recommend their provider to other small and medium-sized enterprises (SMEs).'
        },
        {
          text: 'Account highlights',
          className: 'small-column',
          tooltip: 'These are the features that the bank has decided to showcase to you about their business current account.'
        }
      ],
      bankComparisonStatusSteps: [
        {
          status: null,
          max: 4
        },
        {
          status: 'NotStarted',
          max: 2
        },
        {
          status: 'Processing',
          max: 1 * 60
        },
        {
          status: 'Completed',
          max: 4
        }
      ],
      bankComparisonStatus: 'Processing', // ['NotStarted', 'Processing', 'Completed', 'Failed']
      bankComparison: null,
      isLoading: true,
      isIntegrationAlertVisible: false,
      loadBankComparisonTimerId: null
    }
  },
  computed: {
    ...mapGetters({
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION,
      bankMandateList: bankGetters.BANK_MANDATE_LIST
    })
  },
  methods: {
    closeAlert() {
      this.isIntegrationAlertVisible = false
    },
    getStaticBankComparisonList() {
      return new Promise((resolve, reject) => {
        return resolve({ data: staticBankingData() })
      })
    },
    loadBankComparison() {
      return this.getStaticBankComparisonList()
        .then(res => {
          this.bankComparison = res.data
          return res
        })
        .catch(e => {
          this.bankComparison = []
        })
    },
    checkAlertVisibility() {
      const forwardAIIntegrationSuccessState = ['connected', 'ready']
      this.isIntegrationAlertVisible = !this.bankMandateList.length || (!this.forwardAIIntegration && !forwardAIIntegrationSuccessState.includes(this.forwardAIIntegration.status))
    },
    async init() {
      await this.loadBankComparison()
      this.bankComparisonStatus = 'Completed'
      this.checkAlertVisibility()
      // For better UX
      setTimeout(() => {
        this.isLoading = false
      }, 400)
    },
    trackPageView() {
      this.$ma.trackEvent('PV Banking')
    }
  },
  beforeRouteEnter(to, from, next) {
    // for redirection (especially) from truelayer
    if (from.name !== 'savings-banking') {
      next({ name: 'savings-banking' })
    } else {
      next()
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.trackPageView()
  },
  beforeDestroy() {
    clearTimeout(this.loadBankComparisonTimerId)
  }
}
</script>
